<template>
  <div class="box-wrap">
    <div class="header-box">
      <Search
        v-if="showFlag"
        :code="this.cityCode"
        @changeCity="changeCity"
        @emit-search="searchClick"
      />
    </div>
    <div class="list-box" v-if="cemeteryList.length > 0">
      <div
        class="list-item"
        :class="{ 'border-bottom': index < cemeteryList.length - 1 }"
        v-for="(item, index) of cemeteryList"
        :key="'home_' + index"
        @click="goDetail(item)"
      >
        <div class="item-top">
          <img class="item-img" :src="item.listImages[0]" alt="" />
          <div class="item-info">
            <div class="info-top">
              <div class="name-text">{{ item.cemeteryName }}</div>
              <div class="regionName-text">{{ item.regionName }}</div>
            </div>
            <div class="info-price">
              <p class="price-text">
                <span>¥</span>{{ item.price | setPrice }}
              </p>
              <span class="wan">万</span>
              <i class="qi">起</i>
            </div>
            <div class="info-ld two-line">{{ item.description }}</div>
          </div>
        </div>
        <div class="item-btm">
          <div class="item-tags">
            <template v-for="(tag, tagIndex) of item.tag">
              <div
                v-if="tagIndex < 3"
                class="tags-item"
                :key="index + '-' + tagIndex"
              >
                {{ tag }}
              </div>
            </template>
          </div>
          <div class="item-contacts">
            <a class="tel-btn" @click.stop="telClick" href="tel:4008122133"
              >电话咨询</a
            >
            <div class="yy-btn" @click.stop="showForm(item)">预约看墓</div>
          </div>
        </div>
      </div>
      <Nomore />
    </div>
    <div class="null-box" v-else>
      <img class="null-img" src="@/assets/img/search/search-null.png" alt="" />
      <p class="null-text">没有找到符合条件的陵园</p>
    </div>
    <van-popup
      v-model="showModal"
      safe-area-inset-bottom
      position="bottom"
      closeable
    >
      <div class="form-wrap">
        <FormModal :startTime="startTime" :code="code" />
      </div>
    </van-popup>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getPageListNewApi, collectPointApi } from "@/api/apiList.js";
export default {
  name: "searchList",
  components: {
    Search: () => import("@/components/search/index.vue"),
    Nomore: () => import("@/components/nomore/index.vue"),
    FormModal: () => import("@/views/homerec/form.vue"),
  },
  filters: {
    setPrice(p) {
      let str = (p / 1000000 + "").split(".");
      if (str.length > 1) {
        str = str[0] + "." + str[1].slice(0, 2);
      } else {
        str = str[0] || 0 + ".00";
      }
      return str;
    },
  },
  data() {
    return {
      cityCode: "",
      cemeteryList: [],
      showModal: false,
      code: "",
      startTime: 0,
      showFlag: false
    };
  },
  computed: {
    ...mapGetters(["pages", "search", "UUID"]),
  },
  activated() {
    this.showFlag = false
    this.startTime = +new Date();
    this.cityCode = this.$route.query.code || "";
    this.$nextTick(()=> {
      this.showFlag = true
    })
    this.initFun();
  },
  methods: {
    changeCity(code) {
      this.cityCode = code;
      this.getPageLisFun();
    },
    searchClick(name) {
      this.getPageLisFun(name);
    },
    initFun() {
      this.getPageLisFun(this.search);
    },
    getPageLisFun(cemeteryName) {
      let toast = this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      return new Promise((resolve) => {
        let params = {
          cityCode: this.cityCode,
          size: 999,
          current: 1,
        };
        if (cemeteryName) {
          params.cemeteryName = cemeteryName;
        }
        getPageListNewApi(params)
          .then((res) => {
            let data = res.data?.data?.records || [];
            this.cemeteryList = data;
          })
          .finally(() => {
            toast.clear();
            resolve();
          });
      });
    },
    goDetail(data) {
      if (this.pages.channel && this.pages.subChannel) {
        this.$router.push({
          path: `/rec/detail/${this.pages.channel}/${this.pages.subChannel}?code=${data.code}`,
          query: {
            title: data.cemeteryName,
          },
        });
      } else {
        this.$router.push({
          path: `/rec/detail?code=${data.code}`,
          query: {
            title: data.cemeteryName,
          },
        });
      }
    },
    showForm(data) {
      this.code = data.code || "";
      this.showModal = true;
    },
    telClick() {
      this.buryingPage(3);
    },
    // 埋点
    buryingPage(type) {
      this.collectPointParams = {
        channelCode: this.pages.channel,
        subChannelCode: this.pages.subChannel,
        activityId: this.activityId,
        eventType: type,
        uuid: this.UUID,
        localUrl: location.href,
      };
      collectPointApi(this.collectPointParams)
        .then((res) => {
          console.log(res, "埋点成功");
        })
        .catch((err) => {
          console.log(err, "买点失败");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin flex {
  display: flex;
  align-items: center;
}
i {
  font-style: normal
}
.box-wrap {
  background: #fff;
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: calc(0.1rem + env(safe-area-inset-bottom));
  overflow-y: auto;
  .header-box {
    padding: 0.24rem;
  }
  .null-box {
    padding-top: 1.6rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    .null-img {
      width: 5.5rem;
      height: 3.15rem;
    }
    .null-text {
      padding-top: 0.2rem;
      font-size: 0.28rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 0.4rem;
    }
  }
  .list-box {
    padding: 0 0.24rem;
    .list-item {
      padding: 0.3rem 0;
      &:first-child {
        padding-top: 0.06rem;
      }
      .item-top {
        display: flex;
        .item-img {
          width: 2.85rem;
          height: 1.9rem;
          border-radius: 0.1rem;
        }
        .item-info {
          width: 0;
          flex: 1;
          padding-left: 0.24rem;
          .info-top {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            .name-text {
              font-size: 0.32rem;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
              line-height: 0.36rem;
              width: 0;
              flex: 1;
            }
            .regionName-text {
              font-size: 0.28rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
            }
          }
          .info-price {
            display: flex;
            align-items: baseline;
            padding-top: 0.2rem;
            .wan,
            .qi {
              font-size: 0.24rem;
              color: #999999;
            }
            .wan {
              color: #951e15;
            }
            .price-text {
              font-size: 0.32rem;
              font-family: PingFangSC-Regular, PingFang SC;
              color: #951e15;
              line-height: 0.28rem;
              font-weight: bold;
              span {
                font-size: 0.24rem;
                font-weight: 400;
              }
            }
          }
          .info-ld {
            padding-top: 0.16rem;
            font-size: 0.28rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 0.4rem;
          }
        }
      }
      .item-btm {
        @include flex();
        padding-top: 0.12rem;
        .item-tags {
          @include flex();
          flex-wrap: wrap;
          width: 0;
          flex: 1;
          margin-right: 0.2rem;
          .tags-item {
            width: 1.2rem;
            height: 0.4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff2e4;
            border-radius: 0.08rem;
            font-size: 0.24rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ff9d20;
            margin-right: 0.06rem;
            &:last-of-type {
              margin: 0;
            }
          }
        }
        .item-contacts {
          flex: none;
          @include flex();
          .tel-btn {
            @include flex();
            width: 1.44rem;
            height: 0.48rem;
            border-radius: 0.28rem;
            border: 1px solid #951e15;
            justify-content: center;
            font-size: 0.28rem;
            font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
            font-weight: 400;
            color: #951e15;
          }
          .yy-btn {
            margin-left: 0.12rem;
            @include flex();
            justify-content: center;
            width: 1.44rem;
            height: 0.48rem;
            background: #ff9d20;
            border-radius: 0.28rem;
            border: 1px solid #ff9d20;
            font-size: 0.28rem;
            font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
    }
  }
}
.form-wrap {
  padding: 0.5rem;
}
</style>